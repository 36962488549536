import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy } from './item.css';
// import { Link } from '@reach/router';

const Item = ({ title, copy, image, url }) => (
  <figure>
    <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
    <figcaption>
      {url ? (
        <a href={url}>
          <Title>{title}</Title>
        </a>
      ) : (
        <Title>{title}</Title>
      )}
      <Copy>
        {copy}
        {/* {url && <a href={url}><span role="img" aria-label="Go to link" style={{paddingLeft:5}}>🔗</span></a>} */}
      </Copy>
    </figcaption>
  </figure>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
};

export default Item;
